import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import block from 'bem-cn';

import Button from 'components/Button/desktop';
import Input from 'components/Input';
import { actions } from 'features/users/redux';

import './Deposit.scss';

const b = block('deposit-modal-users-desktop');

const Deposit = ({ closeModal, locale, user }) => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState();

  const onSubmit = useCallback(() => {
    dispatch(actions.depositOrWithdrawal({ amount, operation: 0, id: user.id, callback: closeModal }));
  }, [amount, closeModal, dispatch, user]);

  const changeValue = useCallback(e => {
    setAmount(e.currentTarget.value);
  }, []);

  return (
    <div className={b()}>
      <div className={b('header-info')}>
        <div className={b('header-info', 'block')}>
          <div className={b('header-info', 'block-title')}>{locale.agent}</div>
          <div className={b('header-info', 'block-username')}>{user.parentAgent.username}</div>
          <div className={b('header-info', 'block-balance')}>{user.parentAgent.balance}</div>
        </div>
        <div className={b('header-info', 'block')}>
          <div className={b('header-info', 'block-title')}>{locale.user}</div>
          <div className={b('header-info', 'block-username')}>{user.username}</div>
          <div className={b('header-info', 'block-balance')}>{user.balance}</div>
        </div>
      </div>

      <div className={b('amount-wrapper')}>
        <div className={b('amount-wrapper', 'title')}>{locale.amount}</div>
        <div className={b('amount-wrapper', 'input')}>
          <Input placeholder="900,00" onChange={changeValue} size="high" type="number" value={amount} name="amount" />
        </div>
      </div>

      <div className={b('bottom')}>
        <div className={b('buttons')}>
          <Button callBack={closeModal} text={locale.cancel} size="high" color="modalWhite" />
          <Button type="submit" callBack={onSubmit} text={locale.deposit} size="high" color="modalBlue" />
        </div>
      </div>
    </div>
  );
};

export default Deposit;
