import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import block from 'bem-cn';

import Button from 'components/Button/desktop';
import Input from 'components/Input';
import { actions } from 'features/users/redux';

import './ChangePassword.scss';

const b = block('change-password-modal-users-desktop');

const ChangePassword = ({ closeModal, locale, user }) => {
  const dispatch = useDispatch();
  const [password, setPassword] = useState('');

  const onSubmit = useCallback(() => {
    dispatch(actions.changePassword({ data: { password }, id: user.id, callback: closeModal }));
  }, [password, closeModal, dispatch, user]);

  const changeValue = useCallback(e => {
    setPassword(e.currentTarget.value);
  }, []);

  return (
    <div className={b()}>
      <div className={b('header-info')}>
        <div className={b('header-info', 'block')}>
          <div className={b('header-info', 'block-title')}>{locale.id}</div>
          <div className={b('header-info', 'block-username')}>{user.id}</div>
        </div>
        <div className={b('header-info', 'block')}>
          <div className={b('header-info', 'block-title')}>{locale.password}</div>
          <div className={b('header-info', 'input')}>
            <Input
              placeholder={locale.password}
              onChange={changeValue}
              size="high"
              type="text"
              value={password}
              name="password"
            />
          </div>
        </div>
      </div>

      <div className={b('bottom')}>
        <div className={b('buttons')}>
          <Button callBack={closeModal} text={locale.cancel} size="high" color="modalWhite" />
          <Button type="submit" callBack={onSubmit} text={locale.accept} size="high" color="modalBlue" />
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
