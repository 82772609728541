import React, { useCallback, useState } from 'react';
import block from 'bem-cn';
import PropTypes from 'prop-types';

import SVG from 'components/SVG';

import Dropdown from './Dropdown';

import firstArrowSVG from './img/first-arrow.svg';
import lastArrowSVG from './img/last-arrow.svg';
import nextArrowSVG from './img/next-arrow.svg';
import previousSVG from './img/previous-arrow.svg';

import './Paginator.scss';

const b = block('paginator-desktop');

const Paginator = ({ count, currentPage, onPageClick, onItemsOnPageChanged, color }) => {
  const itemsOnPageFilters = [
    { value: 10, name: '10' },
    { value: 25, name: '25' },
    { value: 50, name: '50' },
    { value: 100, name: '100' },
  ];
  const [itemsOnPage, setItemsOnPage] = useState({ value: 10, name: '10' });

  const pagesCount = Math.ceil(count / itemsOnPage.value);
  const trueCurrentPage = currentPage;

  const getPagesSmall = useCallback(() => {
    let result = [];
    const smallCondition = pagesCount < 3 ? pagesCount : 3;
    for (let i = 0; i < smallCondition; i += 1) {
      const isActive = i === currentPage;
      const el = (
        <div key={i} className={b('page', { active: isActive })} onClick={isActive ? null : () => onPageClick(i)}>
          {i + 1}
        </div>
      );
      result.push(el);
    }
    if (result.length) return result;
    return [
      <div className={b('page', { active: true })} onClick={null}>
        1
      </div>,
    ];
  }, [b, currentPage, itemsOnPage, onPageClick, pagesCount]);

  const getPages = useCallback(() => {
    let result = [];
    const condition = trueCurrentPage + 2 > pagesCount ? pagesCount : trueCurrentPage + 2;
    for (let i = trueCurrentPage - 2; i < condition; i += 1) {
      const isActive = i === currentPage;
      const el = (
        <div key={i} className={b('page', { active: isActive })} onClick={isActive ? null : () => onPageClick(i)}>
          {i + 1}
        </div>
      );
      result.push(el);
    }
    return result;
  }, [b, currentPage, itemsOnPage, onPageClick, pagesCount, trueCurrentPage]);

  const pages = () => (currentPage <= 2 ? getPagesSmall() : getPages());

  const goToFirstPage = () => trueCurrentPage !== 0 && onPageClick(0);
  const goToPrevPage = () => trueCurrentPage - 1 >= 0 && onPageClick(trueCurrentPage - 1);
  const goToNextPage = () => trueCurrentPage + 1 <= pagesCount - 1 && onPageClick(trueCurrentPage + 1);
  const goToLast = () => trueCurrentPage !== pagesCount && onPageClick(pagesCount - 1);

  const selectItemChangedHandler = itemValue => {
    setItemsOnPage({ value: itemValue, name: itemValue });
    onItemsOnPageChanged(itemValue);
  };

  return (
    <div className={b({ color })}>
      <SVG svgProps={{ svg: firstArrowSVG }} className={b('arrow')} onClick={() => goToFirstPage()} />
      <SVG svgProps={{ svg: previousSVG }} className={b('arrow')} onClick={() => goToPrevPage()} />

      <div className={b('pages')}>{pages()}</div>

      <SVG svgProps={{ svg: nextArrowSVG }} className={b('arrow')} onClick={() => goToNextPage()} />
      <SVG svgProps={{ svg: lastArrowSVG }} className={b('arrow')} onClick={() => goToLast()} />

      <div className={b('dropdown')}>
        <Dropdown
          items={itemsOnPageFilters}
          activeItem={itemsOnPage}
          onChange={selectItemChangedHandler}
          color={color}
        />
      </div>
    </div>
  );
};

Paginator.propTypes = {
  count: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onItemsOnPageChanged: PropTypes.func,
  onPageClick: PropTypes.func.isRequired,
};

export default Paginator;
