import { useCallback, useMemo } from 'react';
import { actions } from 'features/users/redux';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

export const usePermissionDetails = userId => {
  const userPermissions = useSelector(state => state.users.userPermissions, shallowEqual);
  const dispatch = useDispatch();

  const parsedPermissions = useMemo(
    () =>
      Object.entries(userPermissions).map(([type, { providers, ...data }]) => {
        return [
          type,
          {
            ...data,
            type,
            level: 0,
            providers: providers.map(provider => ({
              ...provider,
              type,
              locale: provider.provider[0].toUpperCase() + provider.provider.substring(1),
              level: 1,
            })),
          },
        ];
      }),
    [userPermissions],
  );

  const permissions = useMemo(() => Object.fromEntries(parsedPermissions), [parsedPermissions]);

  const changeBooleanPermissionsField = useCallback(
    ({ type, fieldName, provider }) => {
      if (permissions[type][fieldName] === undefined) return;
      if (provider) {
        const newPermissions = {
          ...permissions,
          [type]: {
            ...permissions[type],
            providers: permissions[type].providers.map(el =>
              el.provider === provider ? { ...el, [fieldName]: !el[fieldName] } : el,
            ),
          },
        };
        dispatch(actions.setUserPermissions({ data: newPermissions, noRequest: true }));
      } else {
        const newPermissions = {
          ...permissions,
          [type]: {
            ...permissions[type],
            [fieldName]: !permissions[type][fieldName],
          },
        };
        dispatch(actions.setUserPermissions({ data: newPermissions, noRequest: true }));
      }
    },
    [permissions, dispatch],
  );

  const handleSetPermissions = useCallback(
    ({ type, provider, changeSubstructure, isActive: isActiveProp = null }) => {
      const providers = permissions[type].providers;
      if (!provider) {
        const isActive = isActiveProp ?? !permissions[type].isActive;
        // const newProviders = providers.length ? [{ ...providers[0], isActive }, ...providers.slice(1)] : [];
        const newProviders = providers.map(el => ({ ...el, isActive }));
        const newPermissions = {
          ...permissions,
          [type]: {
            ...permissions[type],
            isActive: isActive,
            providers: isActive ? newProviders : newProviders.map(el => ({ ...el, isActive: isActive })),
          },
        };
        console.log(userId);
        dispatch(
          actions.setUserPermissions({
            data: newPermissions,
            target: {
              type,
              changeSubstructure: changeSubstructure ?? permissions[type].changeSubstructure,
              // provider: newProviders.length && isActive ? newProviders[0].provider : provider,
              isActive,
              userId,
            },
          }),
        );
      } else {
        const newProviders = [...providers];
        const matchProviderIdx = newProviders.findIndex(el => el.provider === provider);
        const isActive = isActiveProp ?? !newProviders[matchProviderIdx].isActive;
        newProviders.splice(matchProviderIdx, 1, {
          ...newProviders[matchProviderIdx],
          isActive: isActive,
        });
        // const isAllProvidersDisabled = newProviders.findIndex(provider => provider.isActive) === -1;
        const isOneDisableProvider = newProviders.findIndex(provider => !provider.isActive) !== -1;
        const isParentActive = (isActive || permissions[type].isActive) && !isOneDisableProvider;
        const newPermissions = {
          ...permissions,
          [type]: {
            ...permissions[type],
            isActive: isParentActive,
            providers: newProviders,
          },
        };
        dispatch(
          actions.setUserPermissions({
            data: newPermissions,
            target: {
              type,
              changeSubstructure: changeSubstructure ?? newProviders[matchProviderIdx].changeSubstructure,
              provider,
              isActive,
              userId,
            },
          }),
        );
      }
    },
    [permissions, dispatch, userId],
  );

  return { permissions, handleSetPermissions, changeBooleanPermissionsField };
};
