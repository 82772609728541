import React from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';

import SVG from 'components/SVG';

import arrowSVG from './img/arrow-right.svg';

import './TableRow.scss';

const b = block('table-row-cash-desktop');

const TableRow = ({ item, locale }) => {
  return (
    <>
      <div className={b('td')}>{item.id}</div>
      <div className={b('td')}>{item.createdAt}</div>
      <div className={b('td')}>{item.operation === 0 ? locale.deposit : locale.withdrawal}</div>
      <div className={b('td')}>
        <div className={b('td-left')}>{item.initiatorUser}</div>
      </div>
      <div className={b('td')}>
        <div className={b('td-left')}>{item.fromUser}</div>
      </div>
      <div className={b('td')}>
        <div className={b('td-left')}>{item.toUser}</div>
      </div>

      <div className={b('td')}>
        <div className={b('td-left')}>{item.amount}</div>
      </div>
      <div className={b('td')}>
        <div className={b('td-left')}>
          {item.balanceFrom}
          <SVG svgProps={{ svg: arrowSVG }} className={b('arrow')} />
          {item.balanceTo}
        </div>
      </div>
    </>
  );
};

TableRow.propTypes = {
  item: PropTypes.object.isRequired,
};

export default TableRow;
