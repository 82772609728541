import React, { useCallback, useState } from 'react';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';

import passwordSVG from './img/password.svg';
import passwordOffSVG from './img/passwordOff.svg';

import './Input.scss';

const b = block('input');

const Input = ({
  value,
  name,
  callBack,
  placeholder = '',
  size = 'default',
  type = 'text',
  isRequired,
  withEye,
  onChange,
  color,
  ...props
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const isPassword = type === 'password' && withEye;
  const passwordClick = useCallback(() => setPasswordVisible(!passwordVisible && isPassword), [
    passwordVisible,
    isPassword,
  ]);
  const currentType = { [true]: 'text', [false]: 'password' };

  return !withEye ? (
    <input
      type={type}
      name={name}
      value={value}
      placeholder={placeholder || ''}
      onChange={callBack || onChange}
      className={b({ sizable: size, type, color })}
      required={isRequired}
    />
  ) : (
    <div className={b('wrapper')}>
      {isPassword && (
        <SVGInline
          svg={passwordVisible ? passwordSVG : passwordOffSVG}
          className={b('password').toString()}
          onClick={passwordClick}
        />
      )}
      <input
        type={isPassword ? currentType[passwordVisible] : type}
        name={name}
        value={value}
        placeholder={placeholder || ''}
        onChange={callBack || onChange}
        className={b({ sizable: size, type, 'with-eye': withEye, color })}
        required={isRequired}
        {...props}
      />
    </div>
  );
  // return (
  //   <input
  //     type={type}
  //     name={name}
  //     value={value}
  //     placeholder={placeholder || ''}
  //     onChange={callBack}
  //     className={b({ sizable: size, type })}
  //     required={isRequired}
  //     {...props}
  //     />
  // );
};

export default Input;
