import React, { useContext } from 'react';
import { block } from 'bem-cn';
import { ModalContext } from 'components/ModalProvider/desktop/ModalProvider';
import { shallowEqual, useSelector } from 'react-redux';

import Input from 'components/Input';
import SVG from 'components/SVG';
import Button from 'components/Button/desktop';

import CreatePlayer from '../Modals/CreatePlayer/CreatePlayer';
import CreateAgent from '../Modals/CreateAgent/CreateAgent';

import searchSvg from './img/search.svg';

import './UsersFilterBlock.scss';

const b = block('filters-users-desktop');

const UsersFilterBlock = ({ filterValue, changeFilterValue, callBack, actionProcessing }) => {
  const { role } = useSelector(state => state.auth, shallowEqual);
  const locale = useSelector(state => state.locale.locale);
  const { openModal, closeModal } = useContext(ModalContext);

  const handleClickNewPlayer = () => {
    openModal({
      title: locale.newPlayer,
      content: <CreatePlayer closeModal={closeModal} locale={locale} />,
    });
  };

  const handleClickNewAgent = () => {
    openModal({
      title: locale.newAgent,
      content: <CreateAgent closeModal={closeModal} locale={locale} />,
    });
  };

  return (
    <div className={b()}>
      <div className={b('search')}>
        <Input
          value={filterValue}
          callBack={changeFilterValue}
          placeholder={`${locale.search}...`}
          type="modalSecond"
        />
        <button
          disabled={actionProcessing || !filterValue.length}
          className={b('search', 'icon-wrapper')}
          onClick={callBack}>
          <SVG svgProps={{ svg: searchSvg }} className={b('search', 'icon').toString()} />
        </button>
        {/* <span className={b('advice')}>{locale.enterTextInLowercaseOnly}</span> */}
      </div>
      <div className={b('buttons')}>
        <Button disabled={role === 7} callBack={handleClickNewAgent} text={locale.newAgent} />
        <Button callBack={handleClickNewPlayer} text={locale.newPlayer} color="hollow" />
      </div>
    </div>
  );
};

export default UsersFilterBlock;
