import React, { useCallback, useMemo, useState } from 'react';
import block from 'bem-cn';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { actions as treeActions } from 'features/tree';

import SVG from 'components/SVG';

import arrowSvg from '../img/arrow.svg';
import userSvg from '../img/user.svg';

import './TreeRow.scss';

const b = block('tree-row-desktop');

const TreeRow = ({ row, nesting }) => {
  const dispatch = useDispatch();

  const selectedAgentId = useSelector(state => state.tree.selectedAgentId);

  const { userId, subusersCount } = row;

  const [isOpened, setOpened] = useState(row.isOpened ?? (row.childrens?.length ? false : null));

  const handleLoadUsersList = useCallback(() => {
    dispatch(treeActions.getUsersByUserId({ id: userId, nesting }));
  }, [nesting, dispatch, userId]);

  const selectAgent = useCallback(
    id => {
      dispatch(treeActions.selectAgentId(id));
    },
    [dispatch],
  );

  const onClick = useCallback(() => {
    selectAgent(userId);
    if (subusersCount) {
      if (isOpened === null || !isOpened) {
        setOpened(true);
        handleLoadUsersList();
      } else {
        setOpened(prevState => !prevState);
      }
    }
  }, [isOpened, subusersCount, handleLoadUsersList, selectAgent, userId]);

  const childrens = useMemo(
    () => row.childrens?.map(t => <TreeRow key={t.userId} nesting={[...nesting, t.userId]} row={t} />) || [],
    [row, nesting],
  );

  return (
    <div className={b()}>
      <div className={b('parent')} onClick={onClick}>
        <div className={b('parent', 'td')}>
          {row.subusersCount ? (
            <SVG
              svgProps={{ svg: arrowSvg }}
              className={b('parent', 'img-arrow', { opened: isOpened, active: selectedAgentId == userId })}
            />
          ) : null}
          <SVG
            svgProps={{ svg: userSvg }}
            className={b('parent', 'img-user', { opened: isOpened, active: selectedAgentId == userId })}
          />
          <span className={b('parent', 'title', { opened: isOpened, active: selectedAgentId == userId })}>
            {row.username}
          </span>
        </div>
      </div>
      {isOpened && <div className={b('childrens')}>{childrens}</div>}
    </div>
  );
};

TreeRow.propTypes = {};

export default TreeRow;
