import { errorStatusMessage } from './errorStatusMessage';

export const locale = {
  ...errorStatusMessage,
  changePassword: 'Cambia la contraseña',
  lastMovements: 'Últimos movimientos',
  logout: 'Cerrar sesión',

  agentPlatfrom: 'Plataforma de agentes',
  email: 'Correo electrónico',
  password: 'Clave',
  login: 'Acceso',
  username: 'Nombre de usuario',
  usernameOrEmail: 'Nombre de usuario o correo electrónico',
  role: 'Rol',

  agents: 'Agentes',
  agent: 'Agente',
  cashier: 'Agente Cajero',
  seeCashier: 'ver agente cajero',

  emtretenimiento: 'Somos emtretenimiento',

  users: 'Usuarios',
  seeAll: 'Ver todo',
  seeAgents: 'Ver agentes',
  seePlayers: 'ver jugadores',
  createAgent: 'Crear agente',
  createPlayer: 'Crear jugador',
  reports: 'Informes',
  financialReports: 'Reportes financieros',
  general: 'General',
  cashDepositsWithdrawals: 'Efectivo (depósitos y retiros)',
  playerReports: 'Informes de jugadores',
  playerHistory: 'historial del jugador',
  detailByPlayer: 'Detalle por jugador',
  sportsBets: 'apuestas deportivas',
  finance: 'Finanzas',
  myDebtAccount: 'mi cuenta de deuda',
  accountBalance: 'Saldo de la cuenta',
  howToStart: 'Cómo empezar',

  search: 'Búsqueda',
  newAgent: 'Nuevo agente',
  newPlayer: 'Nuevo jugador',

  allUsers: 'Todos los usuarios',
  players: 'jugadores',
  disabled: 'Desactivado',
  user: 'Usuario',
  balance: 'Balance',
  operations: 'Operaciones',
  actions: 'Comportamiento',
  records: 'Registros',

  withdrawal: 'retiro',
  deposit: 'Depósito',
  cancel: 'Cancelar',
  save: 'Crear',

  name: 'Nombre',
  surname: 'Apellido',
  withdrawalAllowed: 'Retiro permitido',
  createSuccess: 'Éxito',
  amount: 'Monto',

  disable: 'Deshabilitar',
  edit: 'Editar',
  informations: 'Informaciones',
  permissionDetails: 'Detalles del Permiso',
  id: 'ID',
  accept: 'Aceptar',
  disableUser: 'Deshabilitar usuario',
  modifyAgent: 'Modificar agente',
  information: 'Información',
  hierachy: 'jerarquía',
  currency: 'Divisa',
  creationDate: 'Fecha de creación',
  modificationDate: 'Fecha de modificación',
  enable: 'Habilitar',
  enableUser: 'Habilitar usuario',

  today: 'Este Dia',
  yesterday: 'El dia de ayer',
  lastWeek: 'La semana pasada',
  lastMonth: 'El mes pasado',
  dateFrom: 'Fecha de',
  local: 'Local',
  utc: 'UTC',
  dateUntil: 'Fecha hasta',
  help: 'Ayuda',
  show: 'Espectáculo',
  directOnly: 'Solo directo',
  timeZoneMsg:
    'La zona horaria local tiene en cuenta la fecha y la hora que ha configurado en su dispositivo. La zona horaria UTC 00:00 es un estándar de tiempo universal. Si selecciona la opción "Local", se tendrá en cuenta la hora de su país, y si selecciona la opción "UTC", se tendrá en cuenta la hora estándar universal.',
  10: '10',
  total_bet_count: 'Recuento total de apuestas',
  total_bet: 'Apuesta total',
  total_won: 'Total ganado',
  betCount: 'Recuento de apuestas',
  betAmount: 'monto de la apuesta',
  winAmount: 'Cantidad ganada',
  provider: 'Proveedor',

  searchIn: 'Busca en',
  higher: 'Más alto',
  transaction: 'Transacción',
  date: 'Fecha',
  operation: 'Operación',
  agentInitiator: 'Agente iniciador',
  initiator: 'Iniciador',
  destinationAgent: 'Agente de destino',

  reportsGeneral: 'Informes generales',
  playerReportsGeneral: 'Informes de jugadores generales',
  sportBets: 'apuestas deportivas',
  amountIn: 'Cantidad en',
  amountOut: 'importe',
  total: 'Total',

  gameType: 'Tipo de juego',
  statusBet: 'Apuesta de estado',
  win: 'Victoria',
  bet: 'Apuesta',
  permissions: 'Permisos',
  changeSubstructure: 'Copiar permisos a usuarios secundarios',
  refund: 'Reembolso',
  liveCasino: 'Casino en vivo',
  virtualGames: 'Juegos virtuales',
  sport: 'Deporte',

  fiable: 'fiable',
  all: 'Todos',
  casino: 'Casino',
  slots: 'Tragamonedas',
  status: 'Estado',
  balanceBefore: 'Saldo antes',
  balanceAfter: 'Saldo después',
  userId: 'Id de usuario',
  couponId: 'Id del cupón',
  wait: 'Esperar',
  return: 'Devolver',
  loss: 'Pérdida',
  sold: 'Vendido',
  isExpress: 'es expreso',
  createdAt: 'Creado en',
  totalCoeff: 'coeficiente total',
  fromUser: 'Del usuario',
  toUser: 'Al usuario',
  from: 'Del',
  to: 'Al',

  subagentes: 'subagentes',
  newPassword: 'Nueva contraseña',
  repeatPassword: 'Repita la contraseña',
  passwordsDontMatch: '¡Las contraseñas no coinciden!',

  total_income: 'Ingreso total',
  incomeAmount: 'Cantidad de ingresos',

  enterTextInLowercaseOnly: 'Introducir texto sólo en minúsculas',
  depositsAllowed: 'Depósitos permitidos',
  received: 'Recibió',
};
