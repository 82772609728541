import person from './img/person.svg';

import useSidebarMenu from './useSidebarMenu';

const agentsItem = {
  title: 'agents',
  route: '#',
  routeForActiveTab: '#',
  img: person,
  level: 0,
};

export { useSidebarMenu, agentsItem };
