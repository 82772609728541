import React from 'react';
import block from 'bem-cn';

import './TableHead.scss';

const b = block('table-head-player-history-desktop');

const TableHead = ({ items, locale }) => {
  return (
    <>
      {items?.map(el => (
        <div key={el} className={b('table-head')}>
          {locale[el] || el}
        </div>
      ))}
    </>
  );
};

export default TableHead;
