const convertNumber = (number, decimalSigns = 2, withSpaces = false) => {
  if (isNaN(Number(number)) || number === null) return '-';

  if (withSpaces) {
    return Number(number).toLocaleString('ru-RU', { minimumFractionDigits: decimalSigns }).replace(',', '.');
  }
  
  return Number(number)
    .toLocaleString('de-DE', { minimumFractionDigits: decimalSigns, maximumFractionDigits: decimalSigns });
}

export default convertNumber;
