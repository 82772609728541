import React, { useContext, useMemo, useState } from 'react';
import block from 'bem-cn';

import Button from 'components/Button/desktop';
import SVG from 'components/SVG';
import { ModalContext } from 'components/ModalProvider/desktop/ModalProvider';
import Switcher from 'components/Switcher';

import defaultInputSVG from '../img/default-input.svg';
import { usePermissionDetails } from 'hooks/usePermissionDetails';
import PermissionDetails from '../PermissionDetails/PermissionDetails';

import './PermissionDetailsAdvanced.scss';

const b = block('permission-details-advanced');

const PermissionDetailsAdvanced = ({ closeModal, locale, user, permissionItem }) => {
  const { openModal, closeModal: closePermissionDetailsModal } = useContext(ModalContext);

  const [localActive, setLocalActive] = useState(permissionItem.isActive);

  const { permissions, handleSetPermissions, changeBooleanPermissionsField } = usePermissionDetails(user.id);

  const { type, provider } = permissionItem;

  const item = useMemo(
    () => (provider ? permissions[type].providers.find(el => el.provider === provider) : permissions[type]),
    [permissions, provider, type],
  );

  const back = () => {
    openModal({
      title: locale.permissionDetails,
      content: <PermissionDetails closeModal={closePermissionDetailsModal} locale={locale} user={user} />,
    });
  };

  const onSubmit = () => {
    handleSetPermissions({ type, provider, isActive: localActive });
    back();
  };

  const permissionTitle = useMemo(
    () => locale[permissionItem.locale] ?? permissionItem.locale,
    [permissionItem.locale, locale],
  );

  return (
    <div className={b()}>
      <div className={b('input-block')}>
        <div className={b('input-block_icon')}>
          <SVG svgProps={{ svg: defaultInputSVG }} className={b('svg')} />
        </div>
        <div className={b('input')}>{user.username}</div>
      </div>
      <div className={b('input-block')}>
        <div className={b('input-block_icon')}>{locale.permissions}</div>
        <div className={b('input')}>{permissionTitle}</div>
      </div>
      <div className={b('input-block')}>
        <div className={b('input-block_icon')}>{locale.enable}</div>
        <div className={b('switcher-wrapper')}>
          <div className={b('switcher')}>
            <Switcher isActive={localActive} onSwitch={() => setLocalActive(!localActive)} />
          </div>
        </div>
      </div>
      <div className={b('input-block')}>
        <div className={b('input-block_icon', { substructure: true })}>
          {locale.enable}
          <div className={b('switcher')}>
            <Switcher
              isActive={item.changeSubstructure}
              onSwitch={() => changeBooleanPermissionsField({ type, fieldName: 'changeSubstructure', provider })}
            />
          </div>
        </div>
      </div>
      <div className={b('bottom')}>
        <Button callBack={back} text={locale.cancel} size="high" color="modalWhite" />
        <Button type="submit" callBack={onSubmit} text={locale.accept} size="high" color="modalBlue" />
      </div>
    </div>
  );
};

export default PermissionDetailsAdvanced;
