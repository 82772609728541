import EnPNG from './img/en.png';
import EsPNG from './img/es.png';

export const languages = {
  ES: 'es-es',
  EN: 'en-US',
};

export const languagesWithIcons = {
  'en-US': { lang: languages.EN, icon: EnPNG, text: 'English' },
  'es-es': { lang: languages.ES, icon: EsPNG, text: 'Español' },
};

export const shortLanguages = {
  'es-es': 'es',
  'en-US': 'en',
};
