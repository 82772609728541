import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import block from 'bem-cn';

import './TableProviders.scss';
import TableRow from './TableRow/TableRow';
import TableHead from './TableHead/TableHead';

const b = block('table-general-providers-desktop');

const TableProviders = ({ thItems, trItems , leftAlign = false, itemsWithLeftAlign }) => {
  const { tab } = useParams();
  const locale = useSelector(state => state.locale.locale);
  const rows = useMemo(
    () =>
      trItems?.map(item => (
        <TableRow items={Object.values(item)} leftAlign={leftAlign} itemsWithLeftAlign={itemsWithLeftAlign} />
      )),
    [trItems, leftAlign, itemsWithLeftAlign],
  );

  return (
    <div className={b()}>
      <TableHead items={Object.keys(thItems[0])} locale={locale}    />
      {rows}
    </div>
  );
};

TableProviders.propTypes = {};

export default TableProviders;
