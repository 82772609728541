import { actionTypes } from './actions';
import { initialState } from './initial';

export function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ACTION_PROCESSING:
      return {
        ...state,
        actionProcessing: action.payload,
      };
    case actionTypes.CREATE_USER_SUCCESS:
      return {
        ...state,
        actionProcessing: false,
      };

    case actionTypes.GET_USERS_LIST_SUCCESS:
      return {
        ...state,
        usersList: action.payload,
        actionProcessing: false,
      };

    case actionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        actionProcessing: false,
        newPassword: action.payload,
      };

    case actionTypes.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        actionProcessing: false,
        userInfo: action.payload,
      };

    case actionTypes.USER_INFO_DELETE:
      return {
        ...state,
        actionProcessing: false,
        userInfo: {},
        userPermissions: initialState.userPermissions,
      };
    case actionTypes.CLOSE_PERMISSIONS:
      const closedPermissions = Object.fromEntries(
        Object.entries(state.userPermissions).map(([key, body]) => [key, { ...body, isOpen: false }]),
      );
      return {
        ...state,
        userPermissions: closedPermissions,
      };

    case actionTypes.GET_PERMISSIONS:
      const permissions = Object.entries(state.userPermissions).map(([type, body]) => [
        type,
        { ...body, isActive: action.payload.find(permission => permission.type === type).isActive },
      ]);
      return {
        ...state,
        actionProcessing: false,
        userPermissions: Object.fromEntries(permissions),
      };

    case actionTypes.GET_PERMISSIONS_BY_TYPE:
      const permissionType = action.payload.type;
      const permissionsByType = {
        ...state.userPermissions,
        [permissionType]: {
          ...state.userPermissions[permissionType],
          providers: action.payload.providers,
        },
      };
      return {
        ...state,
        actionProcessing: false,
        userPermissions: permissionsByType,
      };
    case actionTypes.SET_PERMISSIONS:
      return {
        ...state,
        actionProcessing: false,
        userPermissions: action.payload,
      };

    case actionTypes.DATA_REFRESH: {
      return {
        ...state,
        actionProcessing: false,
        isNeedToRefresh: !state.isNeedToRefresh,
      };
    }

    default:
      return { ...state };
  }
}
