import React from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';

import ListItem from './ListItem';
import logoPNG from 'shared/img/logo.png';

import './Sidebar.scss';
import { useSidebarMenu } from '../../data';

const b = block('sidebar-desktop');

const Sidebar = ({ changeMenuOpen = () => null }) => {
  const locale = useSelector(state => state.locale.locale);

  const sidebarMenu = useSidebarMenu();

  return (
    <div className={b()}>
      <div className={b('logo-wrapper')}>
        <img src={logoPNG} className={b('logo')} alt="logo" />
        <span className={b('title-logo')}>{locale.emtretenimiento}</span>
      </div>
      <div className={b('menu')}>
        {sidebarMenu.map(el => (
          <ListItem item={el} key={el.title} changeMenuOpen={changeMenuOpen} />
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
