import React from 'react';
import block from 'bem-cn';
import { Link, useLocation } from 'react-router-dom';

import { useTabs } from 'features/users/data';

import './Tabs.scss';

const b = block('tabs-users-desktop');

const Tabs = () => {
  const location = useLocation();

  const tabItems = useTabs();

  return (
    <div className={b()}>
      {tabItems.map((el, index) => (
        <Link to={el.link} key={el.name} className={b('item', { active: location?.pathname.includes(el.link) })}>
          {el.name}
        </Link>
      ))}
    </div>
  );
};

export default Tabs;
