import React, { useState } from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import SVG from 'components/SVG';

import arrowSVG from './img/arrow.svg';

import './Dropdown.scss';

const b = block('dropdown-paginator-desktop');

const Dropdown = ({ items, activeItem, onChange, placeholder = '', color }) => {
  const locale = useSelector(state => state.locale.locale);

  const [isOpen, changeOpen] = useState(false);

  const itemsList = items.map(item => (
    <li key={item.value} className={b('item')} onClick={() => onChange(item.value)}>
      {item.name}
    </li>
  ));

  return (
    <div
      className={b({ open: isOpen, color })}
      onClick={() => changeOpen(!isOpen)}
      onMouseLeave={() => changeOpen(false)}>
      <div className={b('item', { current: true })}>
        {locale[activeItem.name] || activeItem.name || placeholder}
        <SVG className={b('arrow')} svgProps={{ svg: arrowSVG }} />
      </div>
      {isOpen && <ul className={b('items')}>{itemsList}</ul>}
    </div>
  );
};

Dropdown.propTypes = {
  items: PropTypes.array.isRequired,
  activeItem: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  color: PropTypes.string,

  onChange: PropTypes.func.isRequired,
};

export default Dropdown;
