import React from 'react';
import block from 'bem-cn';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SVG from 'components/SVG';

import searchSVG from './img/search.svg';

import './Button.scss';

const b = block('button-desktop');

const Button = ({
  text,
  callBack,
  disabled = false,
  size = 'default',
  color = 'default',
  type = 'button',
  link,
  search,
  upperCased,
}) => {
  return link ? (
    <Link className={b({ sizable: size }, { disable: disabled }, { colors: color }, { upperCased })} to={link}>
      {text}
    </Link>
  ) : (
    <>
      <button
        type={type}
        className={b({ sizable: size, disable: disabled, colors: color, upperCased, search })}
        onClick={disabled ? f => f : callBack}
        disabled={disabled}>
        {search && <SVG svgProps={{ svg: searchSVG }} className={b('search')} />}
        {text}
      </button>
    </>
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
  link: PropTypes.string,
  callBack: PropTypes.func,
  search: PropTypes.bool,
};

export default Button;
