import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import block from 'bem-cn';

import './Table.scss';
import TableRow from './TableRow/TableRow';
import TableHead from './TableHead/TableHead';

const b = block('table-general-desktop');

const Table = ({ thItems, trItems }) => {
  const { tab } = useParams();
  const locale = useSelector(state => state.locale.locale);
  const rows = useMemo(() => trItems?.map(item => <TableRow items={item} />), [trItems]);

  return (
    <div className={b()}>
      <TableHead items={thItems} locale={locale} />
      {rows}
    </div>
  );
};

Table.propTypes = {};

export default Table;
