import React, { useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import block from 'bem-cn';

import Spinner from 'components/Spinner/view/desktop';

import FilterBlock from './FilterBlock';
import Table from './Table/Table';

import './DetailByPlayer.scss';

const b = block('detail-by-player-desktop');

const DetailByPlayer = () => {
  const statisticPlayer = useSelector(state => state.reports.statisticPlayer, shallowEqual);
  const { actionProcessing } = useSelector(state => state.reports, shallowEqual);

  const [count, setCount] = useState(10);
  const [page, setPage] = useState(0);

  const onItemsOnPageChanged = newItemsOnPage => {
    setCount(newItemsOnPage);
    setPage(0);
  };

  return (
    <div className={b()}>
      <Spinner isLoading={actionProcessing} />
      <div className={b('filters')}>
        <FilterBlock count={count} currentPage={page} onPageClick={setPage} />
      </div>
      {/* {statisticPlayer.userStatistic.length !== 0 && ( */}
      <div className={b('table')}>
        <Table
          currentPage={page}
          onPageClick={setPage}
          onItemsOnPageChanged={onItemsOnPageChanged}
          thTdItems={statisticPlayer.userStatistic}
          totalCount={statisticPlayer.details.totalCount}
        />
      </div>
      {/* )} */}
    </div>
  );
};

export default DetailByPlayer;
