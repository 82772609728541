import React, { useState, useCallback, useEffect } from 'react';
import block from 'bem-cn';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';

import Select from 'components/Select/desktop';
import Button from 'components/Button/desktop';
import DateInput from 'components/DateInput';

import DaysFilter from 'features/reports/view/desktop/DaysFilter';

import { providerItems, gameTypes, statusBets } from 'features/reports/data';

import { actions } from 'features/reports/redux';

import './FilterBlock.scss';

const b = block('filters-sports-bets-desktop');

const FilterBlock = ({ count, currentPage, onPageClick }) => {
  const locale = useSelector(state => state.locale.locale);
  const dispatch = useDispatch();
  const [date, setDate] = useState({
    beginDate: new Date(dayjs().add(-1, 'month')),
    endDate: new Date(dayjs().add(1, 'day')),
  });
  const [activeDay, setActiveDay] = useState('');
  const [filters, setFilters] = useState({
    user: '',
    provider: providerItems[0],
    gameType: gameTypes[0],
    statusBet: statusBets[0],
  });

  const onChangeBeginDate = value => {
    if (!isNaN(value.valueOf())) {
      setDate({ ...date, beginDate: value });
    }

    setActiveDay('');
  };

  const onChangeEndDate = value => {
    if (!isNaN(value.valueOf())) {
      setDate({ ...date, endDate: value });
    }
  };

  const handleStatusBetChange = value => {
    const statusBet = statusBets.find(item => item.value === value);
    setFilters({ ...filters, statusBet: statusBet });
  };

  useEffect(() => {
    handleSearch();
  }, [count, currentPage]);

  const onClickSearch = () => {
    handleSearch();
    onPageClick(0);
  };

  const handleSearch = () => {
    const requestedData = {
      username: filters.user,
      count,
      provider: filters.provider.value,
      status: filters.statusBet.value,
      gameType: 'sport',
      page: currentPage,
      ...date,
    };
    dispatch(actions.getSlotsHistory(requestedData));
  };

  const onChangeActiveDay = useCallback(dayFilter => {
    setActiveDay(dayFilter);
    setDate({ ...date, beginDate: new Date(dayjs().add(dayFilter.value, 'day')) });
  }, []);

  return (
    <div className={b()}>
      <div className={b('days')}>
        <DaysFilter activeDay={activeDay} setActiveDay={onChangeActiveDay} />
      </div>
      <div className={b('inputs')}>
        <div className={b('input')}>
          <DateInput
            name="dateFrom"
            text="dateFrom"
            size="middle"
            rounded
            format="dd/MM/yyyy"
            value={date.beginDate}
            onChange={onChangeBeginDate}
          />
        </div>
        <div className={b('input')}>
          <DateInput
            name="dateUntil"
            text="dateUntil"
            size="middle"
            rounded
            format="dd/MM/yyyy"
            value={date.endDate}
            onChange={onChangeEndDate}
          />
        </div>
      </div>
      <div className={b('inputs2')}>
        <div className={b('input')}>
          <p className={b('input-title')}>{locale.statusBet}</p>
          <Select
            name="statusBets"
            items={statusBets}
            activeItem={filters.statusBet}
            onChange={handleStatusBetChange}
          />
        </div>
        <div className={b('button')}>
          <Button callBack={() => onClickSearch()} text={locale.search} search upperCased />
        </div>
      </div>
    </div>
  );
};

export default FilterBlock;
