import React, { useMemo, useState } from "react";
import block from "bem-cn";
import { useDispatch } from "react-redux";

import { languagesWithIcons } from "services/locale";
import { actions } from "../redux";

import "./Locale.scss";

const b = block("locale");

const Locale = () => {
  const dispatch = useDispatch();

  const langItems = useMemo(
    () =>
      Object.entries(languagesWithIcons).map(temp => (
        <div
          key={temp[0]}
          className={b("item")}
          onClick={() => dispatch(actions.changeLang(temp[0]))}
        >
          <img src={temp[1].icon} alt="" className={b("image")} />
        </div>
      )),
    [dispatch]
  );

  return <section className={b()}>{langItems}</section>;
};

export default Locale;
