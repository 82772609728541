/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from 'react';
import block from 'bem-cn';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';

import Select from 'components/Select/desktop';
import Button from 'components/Button/desktop';
import DateInput from 'components/DateInput';
import Input from 'components/Input';

import DaysFilter from 'features/reports/view/desktop/DaysFilter';

import { gameTypes, statusBets, providerItemsByCasinoType } from 'features/reports/data';

import { actions } from 'features/reports/redux';

import './FilterBlock.scss';

const b = block('filters-player-history-desktop');

const FilterBlock = ({ count, currentPage, onPageClick }) => {
  const locale = useSelector(state => state.locale.locale);
  const username = useSelector(state => state.reports.playerName);

  const dispatch = useDispatch();
  const [date, setDate] = useState({
    beginDate: new Date(dayjs().add(-1, 'month')),
    endDate: new Date(dayjs().add(1, 'day')),
  });
  const [activeDay, setActiveDay] = useState('');
  const [filters, setFilters] = useState({
    user: username,
    provider: providerItemsByCasinoType[gameTypes[0].name][0],
    gameType: gameTypes[0],
    statusBet: statusBets[0],
  });

  const onChangeBeginDate = value => {
    if (!isNaN(value.valueOf())) {
      setDate({ ...date, beginDate: value });
    }

    setActiveDay('');
  };

  const onChangeEndDate = value => {
    if (!isNaN(value.valueOf())) {
      setDate({ ...date, endDate: value });
    }
  };

  const handleUserChange = useCallback(
    e => {
      setFilters({ ...filters, [e.currentTarget.name]: e.currentTarget.value });
    },
    [filters],
  );

  const handleProviderChange = value => {
    const providerItem = providerItemsByCasinoType[filters.gameType.name].find(item => item.value === value);
    setFilters({ ...filters, provider: providerItem });
  };

  const handleGameTypeChange = value => {
    const gameType = gameTypes.find(item => item.value === value);
    setFilters({ ...filters, gameType: gameType, provider: providerItemsByCasinoType[gameType.name][0] });
  };
  const handleStatusBetChange = value => {
    const statusBet = statusBets.find(item => item.value === value);
    setFilters({ ...filters, statusBet: statusBet });
  };

  useEffect(() => {
    return () => {
      dispatch(actions.setPlayerUsername(''));
    };
  }, []);

  useEffect(() => {
    handleSearch();
  }, [count, currentPage]);

  const onClickSearch = () => {
    handleSearch();
    onPageClick(0);
  };

  const handleSearch = () => {
    const requestedData = {
      username: filters.user,
      count,
      provider: filters.provider.value,
      status: filters.statusBet.value,
      gameType: filters.gameType.value,
      page: currentPage,
      ...date,
    };
    dispatch(actions.getSlotsHistory(requestedData));
  };

  const onChangeActiveDay = useCallback(dayFilter => {
    setActiveDay(dayFilter);
    setDate({ ...date, beginDate: new Date(dayjs().add(dayFilter.value, 'day')) });
  }, []);

  return (
    <div className={b()}>
      <div className={b('days')}>
        <DaysFilter activeDay={activeDay} setActiveDay={onChangeActiveDay} />
      </div>

      <div className={b('inputs')}>
        <div className={b('input')}>
          <DateInput
            name="dateFrom"
            text="dateFrom"
            size="middle"
            rounded
            format="dd/MM/yyyy"
            value={date.beginDate}
            onChange={onChangeBeginDate}
          />
        </div>
        <div className={b('input')}>
          <DateInput
            name="dateUntil"
            text="dateUntil"
            size="middle"
            rounded
            format="dd/MM/yyyy"
            value={date.endDate}
            onChange={onChangeEndDate}
          />
        </div>
        <div className={b('input')}>
          <p className={b('input-title')}>{locale.user}</p>
          <Input
            size="low"
            type="lowWhite"
            value={filters.user}
            callBack={handleUserChange}
            placeholder={locale.username}
            name="user"
          />
        </div>
      </div>
      <div className={b('inputs2')}>
        <div className={b('input')}>
          <p className={b('input-title')}>{locale.provider}</p>
          <Select
            name="provider"
            items={providerItemsByCasinoType[filters.gameType.name]}
            activeItem={filters.provider}
            onChange={handleProviderChange}
          />
        </div>
        <div className={b('input')}>
          <p className={b('input-title')}>{locale.statusBet}</p>
          <Select
            name="statusBets"
            items={statusBets}
            activeItem={filters.statusBet}
            onChange={handleStatusBetChange}
          />
        </div>
        <div className={b('input')}>
          <p className={b('input-title')}>{locale.gameType}</p>
          <Select name="gameType" items={gameTypes} activeItem={filters.gameType} onChange={handleGameTypeChange} />
        </div>
        <div className={b('button')}>
          <Button callBack={() => onClickSearch()} text={locale.search} search upperCased />
        </div>
      </div>
    </div>
  );
};

export default FilterBlock;
