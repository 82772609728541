import React from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';

import convertNumber from 'shared/utils/convertNumber';

import './TableRow.scss';

const b = block('table-row-general-desktop');

const TableRow = ({ items }) => {
  return (
    <>
      {items?.map((el, index) => (
        <div key={`${el}_${index}`} className={b('td')}>
          {convertNumber(el)}
        </div>
      ))}
    </>
  );
};

TableRow.propTypes = {
  items: PropTypes.array.isRequired,
};

export default TableRow;
