import React from 'react';
import block from 'bem-cn';
import './TableHead.scss';

const TableHead = ({ items, locale  }) => {
  const b = block('table-head-general-providers-desktop');

  return (
    <>
      {items?.map(el => (
        <div key={el} className={b('table-head')}>
          {locale[el]}
        </div>
      ))}
    </>
  );
};

export default TableHead;
