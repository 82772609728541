import { initialState } from './initial';
import { actionTypes } from './actions';

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGOUT:
      return { ...initialState };
    case actionTypes.SIGN_IN_SUCCESS:
      return { ...state, ...action.payload, isAuth: true };
    case actionTypes.SET_PROFILE:
      return { ...state, ...action.payload };
    case actionTypes.GET_BALANCE:
      return { ...state, ...action.payload };

    default:
      return { ...state };
  }
};
