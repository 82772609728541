export const getTitlePageFromRoute = [
  {
    route: '/users/',
    title: 'users',
  },
  {
    route: '/reports/financial-reports/general',
    title: 'reportsGeneral',
  },
  {
    route: '/reports/financial-reports/cash',
    title: 'cashDepositsWithdrawals',
  },
  {
    route: '/reports/financial-reports/cash',
    title: 'cashDepositsWithdrawals',
  },
  {
    route: '/reports/player-reports/player-history',
    title: 'playerHistory',
  },
  {
    route: '/reports/player-reports/detail-by-player',
    title: 'detailByPlayer',
  },
  {
    route: '/reports/player-reports/sport-bets',
    title: 'sportBets',
  },
  {
    route: '/finance/my-debt-account',
    title: 'myDebtAccount',
  },
  // {
  //   route: '/finance/account-balance',
  //   title: 'accountBalance',
  // },
  {
    route: '/finance/how-to-start',
    title: 'howToStart',
  },
];
