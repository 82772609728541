import React from 'react';
import block from 'bem-cn';

import './TableHead.scss';

const b = block('table-head-cash-desktop');

const TableHead = ({ locale }) => {
  return (
    <>
      <div className={b('table-head')}>{locale.transaction}</div>
      <div className={b('table-head')}>{locale.date}</div>
      <div className={b('table-head')}>{locale.operation}</div>
      <div className={b('table-head')}>{locale.initiator}</div>
      <div className={b('table-head')}>{locale.from}</div>
      <div className={b('table-head')}>{locale.to}</div>
      <div className={b('table-head')}>{locale.amount}</div>
      <div className={b('table-head')}>{locale.balance}</div>
    </>
  );
};

export default TableHead;
