import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import block from 'bem-cn';
import { useSelector } from 'react-redux';

import { actions } from 'features/users/redux';

import Button from 'components/Button/desktop';

import './Information.scss';

const b = block('information-modal-users-desktop');

const Information = ({ closeModal, locale }) => {
  const userInfo = useSelector(state => state.users.userInfo);
  const dispatch = useDispatch();

  const onCloseModal = useCallback(() => {
    dispatch(actions.deleteUserInfo());
    closeModal();
  }, [dispatch, closeModal]);

  return (
    <div className={b()}>
      <div className={b('info')}>
        <div className={b('info', 'block')}>
          <div className={b('info', 'block-title')}>{locale.id}</div>
          <div className={b('info', 'block-value')}>{userInfo.id}</div>
        </div>

        <div className={b('info', 'block')}>
          <div className={b('info', 'block-title')}>{locale.username}</div>
          <div className={b('info', 'block-value')}>{userInfo.username}</div>
        </div>
        <div className={b('info', 'block')}>
          <div className={b('info', 'block-title')}>{locale.currency}</div>
          <div className={b('info', 'block-value')}>{userInfo.currency}</div>
        </div>
        <div className={b('info', 'block')}>
          <div className={b('info', 'block-title')}>{locale.creationDate}</div>
          <div className={b('info', 'block-value')}>{userInfo.firstVisit}</div>
        </div>
        <div className={b('info', 'block')}>
          <div className={b('info', 'block-title')}>{locale.modificationDate}</div>
          <div className={b('info', 'block-value')}>{userInfo.lastVisit}</div>
        </div>
        <div className={b('info', 'block')}>
          <div className={b('info', 'block-title')}>{locale.name}</div>
          <div className={b('info', 'block-value')}>{userInfo.name}</div>
        </div>
        <div className={b('info', 'block')}>
          <div className={b('info', 'block-title')}>{locale.surname}</div>
          <div className={b('info', 'block-value')}>{userInfo.surname}</div>
        </div>
      </div>

      <div className={b('bottom')}>
        <div className={b('buttons')}>
          <Button callBack={onCloseModal} text={locale.cancel} size="high" color="modalWhite" />
        </div>
      </div>
    </div>
  );
};

export default Information;
