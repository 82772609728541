import React, { useCallback, useEffect, useMemo, useContext } from 'react';
import block from 'bem-cn';
import { useDispatch } from 'react-redux';

import Button from 'components/Button/desktop';
import SVG from 'components/SVG';
import Input from 'components/Input';
import { ModalContext } from 'components/ModalProvider/desktop/ModalProvider';
import { actions } from 'features/users/redux';

import PermissionDetailsItem from './PermissionDetailsItem/PermissionDetailsItem';
import PermissionDetailsAdvanced from '../PermissionDetailsAdvanced/PermissionDetailsAdvanced';

import { usePermissionDetails } from 'hooks/usePermissionDetails';
import defaultInputSVG from '../img/default-input.svg';

import './PermissionDetails.scss';

const b = block('permission-details');

const PermissionDetails = ({ closeModal, locale, user }) => {
  const dispatch = useDispatch();
  const { openModal, closeModal: closeAdvancedModal } = useContext(ModalContext);

  const { permissions, handleSetPermissions, changeBooleanPermissionsField } = usePermissionDetails(user.id);

  const onCloseModal = useCallback(() => {
    dispatch(actions.deleteUserInfo());
    closeModal();
  }, [dispatch, closeModal]);

  const handleGetPermissionsByType = useCallback(
    key => {
      dispatch(actions.getUserPermissionsByCasinoType({ userId: user.id, type: key }));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(actions.getUserPermissions(user.id));
    return () => dispatch(actions.closePermissions());
  }, []);

  const openAdvancedWindow = useCallback(
    permissionItem =>
      openModal({
        title: locale.permissionDetails,
        content: (
          <PermissionDetailsAdvanced
            permissionItem={permissionItem}
            title={permissionItem.locale}
            closeModal={closeAdvancedModal}
            locale={locale}
            user={user}
          />
        ),
      }),
    [locale, user],
  );

  const Items = useMemo(
    () =>
      Object.entries(permissions).map(([key, { providers, isOpen, ...item }]) => (
        <>
          <PermissionDetailsItem
            user={user}
            item={item}
            locale={locale}
            callback={() => isOpen === false && handleGetPermissionsByType(key)}
            openAdvancedWindow={openAdvancedWindow}
            changeBooleanPermissionsField={changeBooleanPermissionsField}
            handleSetPermissions={handleSetPermissions}
          />
          {!!providers.length &&
            isOpen &&
            providers.map(item => (
              <PermissionDetailsItem
                user={user}
                item={item}
                locale={locale}
                openAdvancedWindow={openAdvancedWindow}
                handleSetPermissions={handleSetPermissions}
              />
            ))}
        </>
      )),
    [permissions, locale, user.role],
  );

  return (
    <div className={b()}>
      <div className={b('input-block')}>
        <div className={b('input-block_icon')}>
          <SVG svgProps={{ svg: defaultInputSVG }} className={b('svg')} />
        </div>
        <Input placeholder={locale.username} size="high" type="modal" value={user.username} name="email" disabled />
      </div>
      <div className={b('switchers')}>{Items}</div>
      <div className={b('bottom')}>
        <Button callBack={onCloseModal} text={locale.cancel} size="high" color="modalWhite" />
      </div>
    </div>
  );
};

export default PermissionDetails;
