import React, { useState } from 'react';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Locale } from 'features/locale';
import HeaderDropdown from './HeaderDropdown';

import userSVG from '../img/user.svg';

import './Header.scss';

const b = block('header-desktop');

const Header = () => {
  const [headerDropdownVisible, setHeaderDropdownVisible] = useState(false);

  const auth = useSelector(state => state.auth);
  const locale = useSelector(state => state.locale.locale);

  return (
    <div className={b()}>
      <div className={b('language-selector')}>
        <Locale />
      </div>
      <div onClick={() => setHeaderDropdownVisible(!headerDropdownVisible)} className={b('user')}>
        <div className={b('user', 'username')}>{`${auth.username} (${locale.id}: ${auth.id})`}</div>
        <SVGInline svg={userSVG} className={b('user', 'img').toString()} />
      </div>
      {headerDropdownVisible && <HeaderDropdown onClose={() => setHeaderDropdownVisible(false)} />}
    </div>
  );
};

export default withRouter(Header);
