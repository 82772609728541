import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import block from 'bem-cn';

import Button from 'components/Button/desktop';
import { actions } from 'features/users/redux';

import './DisableUser.scss';

const b = block('disable-user-modal-users-desktop');

const DisableUser = ({ closeModal, locale, user, isDisable }) => {
  const dispatch = useDispatch();

  const onSubmit = useCallback(() => {
    dispatch(actions.disableUser({ data: { is_banned: isDisable }, id: user.id, callback: closeModal }));
  }, [closeModal, dispatch, user, isDisable]);

  return (
    <div className={b()}>
      <div className={b('header-info')}>
        <div className={b('header-info', 'block')}>
          <div className={b('header-info', 'block-title')}>{locale.name}</div>
          <div className={b('header-info', 'block-username')}>{user.username}</div>
        </div>
      </div>

      <div className={b('bottom')}>
        <div className={b('buttons')}>
          <Button callBack={closeModal} text={locale.cancel} size="high" color="modalWhite" />
          <Button type="submit" callBack={onSubmit} text={locale.accept} size="high" color="modalBlue" />
        </div>
      </div>
    </div>
  );
};

export default DisableUser;
