import React, { useState } from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SVGInlive from 'react-svg-inline';

import ArrowSVG from './img/arrow.svg';

import './Select.scss';

const b = block('select-desktop');

const Select = ({ items, activeItem, onChange, placeholder = '', color = '' }) => {
  const locale = useSelector(state => state.locale.locale);

  const [isOpen, changeOpen] = useState(false);

  const itemsList = items.map(item => (
    <li key={item.value} className={b('item', { color })} onClick={() => onChange(item.value)}>
      {locale[item.name] || item.name}
    </li>
  ));

  return (
    <div
      className={b({ open: isOpen, color })}
      onClick={() => changeOpen(!isOpen)}
      onMouseLeave={() => changeOpen(false)}>
      <div className={b('item', { current: true, color })}>
        {locale[activeItem.name] || activeItem.name || placeholder}
        <SVGInlive className={b('arrow').toString()} svg={ArrowSVG} />
      </div>
      {isOpen && <ul className={b('items-list', { color })}>{itemsList}</ul>}
    </div>
  );
};

Select.propTypes = {
  items: PropTypes.array.isRequired,
  activeItem: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  color: PropTypes.string,

  onChange: PropTypes.func.isRequired,
};

export default Select;
