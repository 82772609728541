import React, { useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import block from 'bem-cn';

import Paginator from 'components/Paginator/desktop';

import TableRow from '../TableRow/TableRow';
import TableHead from '../TableHead/TableHead';

import './Table.scss';

const b = block('table-player-history-desktop');

const Table = ({ currentPage, onPageClick, onItemsOnPageChanged, thTdItems, totalCount }) => {
  const locale = useSelector(state => state.locale.locale, shallowEqual);

  const rows = useMemo(() => thTdItems?.map(item => <TableRow items={Object.values(item)} />), [thTdItems]);

  return (
    <div className={b()}>
      <div className={b('table-wrapper')}>
        <div className={b('table')}>
          <TableHead items={thTdItems.length !== 0 ? Object.keys(thTdItems[0]) : []} locale={locale} />
          {rows}
        </div>
      </div>
      <div className={b('bottom')}>
        <div className={b('extra-info')}>
          {totalCount} {locale.records}
        </div>

        <div className={b('paginator')}>
          <Paginator
            count={totalCount}
            currentPage={currentPage}
            onPageClick={onPageClick}
            onItemsOnPageChanged={onItemsOnPageChanged}
          />
        </div>
      </div>
    </div>
  );
};

Table.propTypes = {
  count: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onItemsOnPageChanged: PropTypes.func,
  onPageClick: PropTypes.func.isRequired,
};

export default Table;
