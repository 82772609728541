import React, { useState, useCallback, useEffect } from 'react';
import block from 'bem-cn';
import dayjs from 'dayjs';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import Button from 'components/Button/desktop';
import DateInput from 'components/DateInput';
import DaysFilter from 'features/reports/view/desktop/DaysFilter';

import { actions } from 'features/finance';

import './FilterBlock.scss';

const b = block('filters-my-debt-account-desktop');

const FilterBlock = ({ count, currentPage, onPageClick }) => {
  const dispatch = useDispatch();

  const locale = useSelector(state => state.locale.locale);
  const { balance } = useSelector(state => state.auth, shallowEqual);

  const [date, setDate] = useState({
    beginDate: new Date(dayjs().add(-1, 'month')),
    endDate: new Date(dayjs().add(1, 'day')),
  });
  const [activeDay, setActiveDay] = useState('');

  const onChangeBeginDate = value => {
    if (!isNaN(value.valueOf())) {
      setDate({ ...date, beginDate: value });
    }
    setActiveDay('');
  };

  const onChangeEndDate = value => {
    if (!isNaN(value.valueOf())) {
      setDate({ ...date, endDate: value });
    }
  };

  useEffect(() => {
    handleSearch();
  }, [count, currentPage]);

  const onClickSearch = () => {
    onPageClick(0);
    handleSearch();
  };

  const handleSearch = () => {
    const requestedData = {
      count,
      page: currentPage,
      ...date,
    };
    dispatch(actions.getFinanceHistory(requestedData));
  };

  const onChangeActiveDay = useCallback(dayFilter => {
    setActiveDay(dayFilter);
    setDate({ ...date, beginDate: new Date(dayjs().add(dayFilter.value, 'day')) });
  }, []);

  return (
    <div className={b()}>
      <div className={b('days')}>
        <DaysFilter activeDay={activeDay} setActiveDay={onChangeActiveDay} />
      </div>

      <div className={b('inputs')}>
        <div className={b('input')}>
          <DateInput
            name="dateFrom"
            text="dateFrom"
            size="middle"
            rounded
            format="dd/MM/yyyy"
            value={date.beginDate}
            onChange={onChangeBeginDate}
          />
        </div>
        <div className={b('input')}>
          <DateInput
            name="dateUntil"
            text="dateUntil"
            size="middle"
            rounded
            format="dd/MM/yyyy"
            value={date.endDate}
            onChange={onChangeEndDate}
          />
        </div>
        <div className={b('balance')}>
          {locale.balance}: <div className={b('balance-value')}>{balance}</div>
        </div>
        <div className={b('button')}>
          <Button callBack={() => onClickSearch()} text={locale.search} search upperCased />
        </div>
      </div>
    </div>
  );
};

export default FilterBlock;
