import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import block from 'bem-cn';

import Spinner from 'components/Spinner/view/desktop';

import FilterBlock from './FilterBlock';
import Table from './Table/Table';
import TableProviders from './TableProviders/TableProviders';

import './General.scss';

const b = block('general-desktop');

const General = () => {
  const { actionProcessing } = useSelector(state => state.reports, shallowEqual);
  const statisticProvider = useSelector(state => state.reports.statisticProvider, shallowEqual);

  return (
    <div className={b()}>
      <Spinner isLoading={actionProcessing} />
      <div className={b('filters')}>
        <FilterBlock />
      </div>
      <div className={b('tables')}>
        <div className={b('table1')}>
          <Table thItems={Object.keys(statisticProvider.detail)} trItems={[Object.values(statisticProvider.detail)]} />
        </div>
        {statisticProvider?.providers.length !== 0 && (
          <div className={b('table2')}>
            <TableProviders thItems={statisticProvider.providers} trItems={statisticProvider.providers} leftAlign />
          </div>
        )}
      </div>
    </div>
  );
};

export default General;
