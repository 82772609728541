import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ModalContext } from 'components/ModalProvider/desktop/ModalProvider';
import SVG from 'components/SVG';
import { actions as usersActions } from 'features/users/redux';

import { actions } from 'features/users/redux';
import depositSVG from 'features/users/img/deposit.svg';
import usernameSVG from 'features/users/img/username.svg';
import agentSVG from 'features/users/img/agent.svg';
import withdrawalSVG from 'features/users/img/withdrawal.svg';
import { getActionItems, getRoleIcon } from 'features/users/data';
import { actions as reportsActions } from 'features/reports/redux';

import convertNumber from 'shared/utils/convertNumber';

import ChangePassword from '../../Modals/ChangePassword/ChangePassword';
import DisableUser from '../../Modals/DisableUser/DisableUser';
import ModifyAgent from '../../Modals/ModifyAgent/ModifyAgent';
import Information from '../../Modals/Information/Information';
import Deposit from '../../Modals/Deposit/Deposit';
import Withdrawal from '../../Modals/Withdrawal/Withdrawal';
import Dropdown from '../Dropdown';
import PermissionDetails from '../../Modals/PermissionDetails/PermissionDetails';

import './TableRow.scss';

const b = block('table-row-users-desktop');

const TableRow = ({ item, isWithdrawalAccess, isTopUpAccess, locale }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { openModal, closeModal } = useContext(ModalContext);

  const isAgent = ['6', '7'].includes(item.role);

  const handleClickDeposit = () => {
    openModal({
      title: locale.deposit,
      content: <Deposit closeModal={closeModal} locale={locale} user={item} />,
    });
  };

  const handleClickWithdrawal = () => {
    openModal({
      title: locale.withdrawal,
      content: <Withdrawal closeModal={closeModal} locale={locale} user={item} />,
    });
  };

  const selectItemChangedHandler = itemTitle => {
    if (itemTitle === 'changePassword') {
      openModal({
        title: locale.changePassword,
        content: <ChangePassword closeModal={closeModal} locale={locale} user={item} />,
      });
    }
    if (itemTitle === 'disable') {
      openModal({
        title: locale.disableUser,
        content: <DisableUser closeModal={closeModal} locale={locale} user={item} isDisable={true} />,
      });
    }
    if (itemTitle === 'enable') {
      openModal({
        title: locale.enableUser,
        content: <DisableUser closeModal={closeModal} locale={locale} user={item} isDisable={false} />,
      });
    }
    if (itemTitle === 'edit') {
      dispatch(actions.getUserInfo({ id: item.id }));
      openModal({
        title: locale.modifyAgent,
        content: <ModifyAgent closeModal={closeModal} locale={locale} user={item} />,
      });
    }
    if (itemTitle === 'informations') {
      dispatch(actions.getUserInfo({ id: item.id }));
      openModal({
        title: locale.informations,
        content: <Information closeModal={closeModal} locale={locale} />,
      });
    }
    if (itemTitle === 'permissionDetails') {
      dispatch(usersActions.getUserInfo({ id: item.id }));
      openModal({
        title: locale.permissionDetails,
        content: <PermissionDetails closeModal={closeModal} locale={locale} user={item} />,
      });
    }
    if (itemTitle === 'lastMovements') {
      dispatch(reportsActions.setPlayerUsername(item.username, item.role));
      history.replace(isAgent ? '/reports/financial-reports/cash' : '/reports/player-reports/player-history');
    }
  };

  const roleIcon = useMemo(() => {
    return getRoleIcon(item.role);
  }, [item.role]);

  return (
    <>
      <div className={b('td')}>
        <div className={b('td-data')}>
          <SVG svgProps={{ svg: roleIcon }} className={b('td-username-img')} />
          {item.username}
        </div>
      </div>
      <div className={b('td')}>
        <div className={b('td-balance')}>{convertNumber(item.balance)}</div>
      </div>
      <div className={b('td')}>
        <div className={b('td-data')}>
          {isTopUpAccess && (
            <SVG onClick={handleClickDeposit} svgProps={{ svg: depositSVG }} className={b('td-deposit-img')} />
          )}
          {isWithdrawalAccess && (
            <SVG onClick={handleClickWithdrawal} svgProps={{ svg: withdrawalSVG }} className={b('td-withdrawal-img')} />
          )}
        </div>
      </div>
      <div className={b('td')}>
        {+item.role !== 3 && (
          <div className={b('td-data-actions')}>
            <Dropdown
              items={getActionItems({ role: item.role, isBanned: item.isBanned })}
              onChange={selectItemChangedHandler}
              locale={locale}
            />
          </div>
        )}
      </div>
    </>
  );
};

TableRow.propTypes = {
  item: PropTypes.object.isRequired,
};

export default TableRow;
