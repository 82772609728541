import React, { useState, useCallback, useEffect } from 'react';
import block from 'bem-cn';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';

import Button from 'components/Button/desktop';
import DateInput from 'components/DateInput';
import Input from 'components/Input';
import CheckBox from 'components/CheckBox';

import DaysFilter from 'features/reports/view/desktop/DaysFilter';

import { actions } from 'features/reports/redux';

import './FilterBlock.scss';

const b = block('filters-detail-by-player-desktop');

const FilterBlock = ({ count, currentPage, onPageClick }) => {
  const dispatch = useDispatch();

  const locale = useSelector(state => state.locale.locale);

  const [date, setDate] = useState({
    beginDate: new Date(dayjs().add(-1, 'month')),
    endDate: new Date(dayjs().add(1, 'day')),
  });
  const [activeDay, setActiveDay] = useState('');
  const [filters, setFilters] = useState({
    user: '',
  });
  const [isDirectOnly, setIsDirectOnly] = useState(false);

  const onChangeBeginDate = value => {
    if (!isNaN(value.valueOf())) {
      setDate({ ...date, beginDate: value });
    }

    setActiveDay('');
  };

  const onChangeEndDate = value => {
    if (!isNaN(value.valueOf())) {
      setDate({ ...date, endDate: value });
    }
  };

  const handleUserChange = useCallback(
    e => {
      setFilters({ ...filters, [e.currentTarget.name]: e.currentTarget.value });
    },
    [filters],
  );

  useEffect(() => {
    handleSearch();
  }, [count, currentPage]);

  const onClickSearch = () => {
    handleSearch();
    onPageClick(0);
  };

  const handleSearch = () => {
    const requestedData = {
      isDirectOnly,
      username: filters.user,
      count,
      page: currentPage,
      ...date,
    };
    dispatch(actions.getStatisticPlayer(requestedData));
  };

  const onChangeActiveDay = useCallback(dayFilter => {
    setActiveDay(dayFilter);
    setDate({ ...date, beginDate: new Date(dayjs().add(dayFilter.value, 'day')) });
  }, []);

  return (
    <div className={b()}>
      <div className={b('days')}>
        <DaysFilter activeDay={activeDay} setActiveDay={onChangeActiveDay} />
      </div>

      <div className={b('inputs')}>
        <div className={b('input')}>
          <DateInput
            name="dateFrom"
            text="dateFrom"
            size="middle"
            rounded
            format="dd/MM/yyyy"
            value={date.beginDate}
            onChange={onChangeBeginDate}
          />
        </div>
        <div className={b('input')}>
          <DateInput
            name="dateUntil"
            text="dateUntil"
            size="middle"
            rounded
            format="dd/MM/yyyy"
            value={date.endDate}
            onChange={onChangeEndDate}
          />
        </div>

        <div className={b('input')}>
          <p className={b('input-title')}>
            {locale.user}
            {/* <span className={b('advice')}>{` (${locale.enterTextInLowercaseOnly})`}</span> */}
          </p>
          <div className={b('input-value')}>
            <Input
              size="default"
              type="lowWhite"
              value={filters.user}
              callBack={handleUserChange}
              placeholder={locale.username}
              name="user"
            />
          </div>
        </div>
        <div className={b('checkbox')}>
          <CheckBox checked={isDirectOnly} onChange={() => setIsDirectOnly(!isDirectOnly)} name="isDirectOnly" />
          <p className={b('checkbox-title')}>{locale.directOnly}</p>
        </div>

        <div className={b('button')}>
          <Button callBack={() => onClickSearch()} text={locale.search} search size="default" upperCased />
        </div>
      </div>
    </div>
  );
};

export default FilterBlock;
