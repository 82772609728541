import { shallowEqual, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';

const useAgentRoles = () => {
  const locale = useSelector(state => state.locale.locale);
  const { role } = useSelector(state => state.auth, shallowEqual);

  const [roles, setRoles] = useState([]);

  useEffect(() => {
    switch (role) {
      case 0:
        return setRoles([
          {
            value: 7,
            text: locale.cashier,
            isActive: true,
          },
        ]);
      case 6:
        return setRoles([
          {
            value: 6,
            text: locale.agent,
            isActive: true,
          },
          {
            value: 7,
            text: locale.cashier,
            isActive: false,
          },
        ]);
      default:
        setRoles([]);
    }
  }, [setRoles, role, locale.cashier, locale.agent]);

  const onRoleChange = useCallback(
    roleValue =>
      setRoles(ps =>
        ps.map(item =>
          roleValue === item.value
            ? {
                ...item,
                isActive: true,
              }
            : { ...item, isActive: false },
        ),
      ),
    [setRoles],
  );

  const selectedRole = useMemo(() => roles.find(role => role.isActive), [roles]);

  return {
    roles,
    selectedRole,
    onRoleChange,
  };
};

export default useAgentRoles;
