import React, { useState, useCallback, useEffect } from 'react';
import block from 'bem-cn';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from 'components/Button/desktop';
import SVG from 'components/SVG';
import Input from 'components/Input';
import Switcher from 'components/Switcher';
import { actions } from 'features/users/redux';
import { useAgentRoles } from 'features/users/data';

import checkboxSVG from '../img/checkbox.svg';
import checkboxOffSVG from '../img/checkboxOff.svg';

import defaultInputSVG from '../img/default-input.svg';
import passwordInputSVG from '../img/password-input.svg';

import './CreateAgent.scss';

const b = block('create-agent-modal-users-desktop');

const CreateAgent = ({ closeModal, locale }) => {
  const dispatch = useDispatch();

  const { role } = useSelector(state => state.auth, shallowEqual);

  useEffect(() => {
    if (role === 7) closeModal();
  }, [closeModal, role]);

  const { roles, onRoleChange, selectedRole } = useAgentRoles();

  const [form, setForm] = useState({
    username: '',
    password: '',
    email: '',
    name: '',
    surname: '',
  });
  const [withdrawalAllowed, setWithdrawalAllowed] = useState(true);
  const [topUpAllowed, setTopUpAllowed] = useState(true);

  const changeValue = useCallback(
    e => {
      setForm({ ...form, [e.currentTarget.name]: e.currentTarget.value.trim() });
    },
    [form],
  );

  const changeValueUsername = useCallback(
    e => {
      setForm({ ...form, username: e.currentTarget.value.trim() });
    },
    [form],
  );

  const onSubmit = useCallback(() => {
    dispatch(
      actions.createAgent({
        form: { ...form, withdrawalAllowed, topUpAllowed, role: selectedRole?.value },
        callback: closeModal,
      }),
    );
  }, [selectedRole, form, closeModal, dispatch, withdrawalAllowed, topUpAllowed]);

  return (
    <div className={b()}>
      <form className={b('form')}>
        <div className={b('form', 'input-block')}>
          <div className={b('form', 'input-block_icon')}>{locale.role}</div>
          {roles.map(item => (
            <button
              key={item.value}
              className={b('role-block')}
              onClick={e => {
                e.preventDefault();
                onRoleChange(item.value);
              }}>
              <div className={b('role-block-svg')}>
                <SVG className={b('role-block-icon', { isActive: item.isActive })} svgProps={{ svg: checkboxSVG }} />
                <SVG
                  className={b('role-block-icon', { isActive: !item.isActive })}
                  svgProps={{ svg: checkboxOffSVG }}
                />
              </div>
              <span className={b('role-block-type')}>{item.text}</span>
            </button>
          ))}
        </div>
        <div className={b('form', 'input-block')}>
          <div className={b('form', 'input-block_icon')}>
            <SVG svgProps={{ svg: defaultInputSVG }} className={b('svg')} />
          </div>
          <Input
            placeholder={locale.username}
            onChange={changeValueUsername}
            size="high"
            type="modal"
            value={form.username}
          />
        </div>
        <div className={b('form', 'input-block')}>
          <div className={b('form', 'input-block_icon')}>
            <SVG svgProps={{ svg: passwordInputSVG }} className={b('svg')} />
          </div>
          <Input
            placeholder={locale.password}
            onChange={changeValue}
            size="high"
            type="modal"
            value={form.password}
            name="password"
          />
        </div>
        <div className={b('form', 'input-block')}>
          <div className={b('form', 'input-block_icon')}>
            <SVG svgProps={{ svg: defaultInputSVG }} className={b('svg')} />
          </div>
          <Input
            placeholder={locale.email}
            onChange={changeValue}
            size="high"
            type="modal"
            value={form.email}
            name="email"
          />
        </div>
        <div className={b('form', 'input-block')}>
          <div className={b('form', 'input-block_icon')}>
            <SVG svgProps={{ svg: defaultInputSVG }} className={b('svg')} />
          </div>
          <Input
            placeholder={locale.name}
            onChange={changeValue}
            size="high"
            type="modal"
            value={form.name}
            name="name"
          />
        </div>
        <div className={b('form', 'input-block')}>
          <div className={b('form', 'input-block_icon')}>
            <SVG svgProps={{ svg: defaultInputSVG }} className={b('svg')} />
          </div>
          <Input
            placeholder={locale.surname}
            onChange={changeValue}
            size="high"
            type="modal"
            value={form.surname}
            name="surname"
          />
        </div>
        <div className={b('form', 'switch-block')}>
          <div className={b('form', 'switch-block_text')}>{locale.depositsAllowed}</div>
          <div className={b('form', 'switch-block_box')}>
            <Switcher isActive={topUpAllowed} onSwitch={setTopUpAllowed} />
          </div>
        </div>
        <div className={b('form', 'switch-block')}>
          <div className={b('form', 'switch-block_text')}>{locale.withdrawalAllowed}</div>
          <div className={b('form', 'switch-block_box')}>
            <Switcher isActive={withdrawalAllowed} onSwitch={setWithdrawalAllowed} />
          </div>
        </div>
      </form>
      <div className={b('bottom')}>
        <Button callBack={closeModal} text={locale.cancel} size="high" color="modalWhite" />
        <Button type="submit" callBack={onSubmit} text={locale.save} size="high" color="modalBlue" />
      </div>
    </div>
  );
};

export default CreateAgent;
