import React, { useMemo, useEffect } from 'react';
import block from 'bem-cn';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import { actions } from 'features/finance';

import './FlatPage.scss';

const b = block('flat-page-desktop');

const FlatPage = ( ) => {
  const { page } = useParams();
  const flatPages = useSelector(state => state.finance.flatPages);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getFlatPages());
  }, []);
  const flatPage = useMemo(() => flatPages.find(el => el.idName === page), [page, flatPages]);

  return (
    <div className={b( )}>
      <div className={b('title')}>{flatPage?.name}</div>
      <article className={b('content')} dangerouslySetInnerHTML={{ __html: flatPage?.text }} />
    </div>
  );
};

export default FlatPage;
