import React from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';

import './TableRow.scss';

const b = block('table-row-player-history-desktop');

const TableRow = ({ items, locale }) => {
  return (
    <>
      {items?.map((el, index) => (
        <div key={`${el}_${index}`} className={b('td')}>
          {el}
        </div>
      ))}
    </>
  );
};

TableRow.propTypes = {
  item: PropTypes.object.isRequired,
};

export default TableRow;
