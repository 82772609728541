import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import block from 'bem-cn';

import { getTitlePageFromRoute } from '../../data';

import './Subheader.scss';

const b = block('subheader');
const Subheader = () => {
  const locale = useSelector(state => state.locale.locale);
  const location = useLocation();

  const getTitle = () => {
    return getTitlePageFromRoute.find(el => location?.pathname.includes(el.route)).title;
  };

  return (
    <div className={b()}>
      <div className={b('first-title')}>{locale.agents}</div>
      <div className={b('second-title')}>{location?.pathname === '/' ? '' : locale[getTitle()]}</div>
    </div>
  );
};

export default Subheader;
