import React, { useState, useCallback, useEffect } from 'react';
import block from 'bem-cn';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/Button/desktop';
import SVG from 'components/SVG';
import Input from 'components/Input';
import Switcher from 'components/Switcher';
import checkboxSVG from '../img/checkbox.svg';
import { actions } from 'features/users/redux';

import defaultInputSVG from '../img/default-input.svg';

import './ModifyAgent.scss';

const b = block('modify-agent-modal-users-desktop');

const ModifyAgent = ({ closeModal, locale, user }) => {
  const dispatch = useDispatch();
  const userInfo = useSelector(state => state.users.userInfo);
  const [form, setForm] = useState({
    username: '',
    email: '',
    name: '',
    surname: '',
  });
  const [topUpAllowed, setTopUpAllowed] = useState(true);
  const [withdrawalAllowed, setWithdrawalAllowed] = useState(true);

  useEffect(() => {
    setForm({
      username: userInfo.username,
      email: userInfo.email,
      name: userInfo.name,
      surname: userInfo.surname,
    });
    setWithdrawalAllowed(userInfo.isWithdrawalAccess);
    setTopUpAllowed(userInfo.isTopUpAccess);
  }, [userInfo]);

  const changeValue = useCallback(
    e => {
      setForm({ ...form, [e.currentTarget.name]: e.currentTarget.value });
    },
    [form],
  );

  const onCloseModal = useCallback(() => {
    dispatch(actions.deleteUserInfo());
    closeModal();
  }, [dispatch, closeModal]);

  const onSubmit = useCallback(() => {
    dispatch(
      actions.modifyAgent({
        data: { ...form, withdrawalAllowed, topUpAllowed },
        id: userInfo.id,
        callback: onCloseModal,
      }),
    );
  }, [form, dispatch, withdrawalAllowed, topUpAllowed, userInfo, onCloseModal]);

  return (
    <div className={b()}>
      <form className={b('form')}>
        <div className={b('form', 'input-block')}>
          <div className={b('form', 'input-block_icon')}>{locale.role}</div>
          <div className={b('role-block')}>
            <SVG svgProps={{ svg: checkboxSVG }} className={b('role-block-svg')} />
            <span className={b('role-block-type')}>{locale.agent}</span>
          </div>
        </div>
        <div className={b('form', 'input-block')}>
          <div className={b('form', 'input-block_icon')}>
            <SVG svgProps={{ svg: defaultInputSVG }} className={b('svg')} />
          </div>
          <Input
            placeholder={locale.username}
            onChange={changeValue}
            size="high"
            type="modal"
            value={form.username}
            name="username"
          />
        </div>
        <div className={b('form', 'input-block')}>
          <div className={b('form', 'input-block_icon')}>
            <SVG svgProps={{ svg: defaultInputSVG }} className={b('svg')} />
          </div>
          <Input
            placeholder={locale.email}
            onChange={changeValue}
            size="high"
            type="modal"
            value={form.email}
            name="email"
          />
        </div>
        <div className={b('form', 'input-block')}>
          <div className={b('form', 'input-block_icon')}>
            <SVG svgProps={{ svg: defaultInputSVG }} className={b('svg')} />
          </div>
          <Input
            placeholder={locale.name}
            onChange={changeValue}
            size="high"
            type="modal"
            value={form.name}
            name="name"
          />
        </div>
        <div className={b('form', 'input-block')}>
          <div className={b('form', 'input-block_icon')}>
            <SVG svgProps={{ svg: defaultInputSVG }} className={b('svg')} />
          </div>
          <Input
            placeholder={locale.surname}
            onChange={changeValue}
            size="high"
            type="modal"
            value={form.surname}
            name="surname"
          />
        </div>
        <div className={b('form', 'switch-block')}>
          <div className={b('form', 'switch-block_text')}>{locale.depositsAllowed}</div>
          <div className={b('form', 'switch-block_box')}>
            <Switcher isActive={topUpAllowed} onSwitch={setTopUpAllowed} />
          </div>
        </div>
        <div className={b('form', 'switch-block')}>
          <div className={b('form', 'switch-block_text')}>{locale.withdrawalAllowed}</div>
          <div className={b('form', 'switch-block_box')}>
            <Switcher isActive={withdrawalAllowed} onSwitch={setWithdrawalAllowed} />
          </div>
        </div>
      </form>
      <div className={b('bottom')}>
        <Button callBack={onCloseModal} text={locale.cancel} size="high" color="modalWhite" />
        <Button type="submit" callBack={onSubmit} text={locale.save} size="high" color="modalBlue" />
      </div>
    </div>
  );
};

export default ModifyAgent;
