import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import block from 'bem-cn';

import Button from 'components/Button/desktop';
import Input from 'components/Input';

import { actions } from 'features/users/redux';
import { addNotify } from 'features/notify';

import './ChangePassword.scss';

const b = block('change-password-header-desktop');

const ChangePassword = ({ closeModal, locale, id }) => {
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    repeatPassword: '',
    newPassword: '',
  });

  const onSubmit = useCallback(() => {
    if (form.repeatPassword === form.newPassword) {
      dispatch(actions.changePassword({ data: { password: form.newPassword }, id, callback: closeModal }));
    } else {
      dispatch(addNotify(locale.passwordsDontMatch, 'error'));
    }
  }, [closeModal, dispatch, id, form, locale]);

  const changeValue = useCallback(
    e => {
      setForm({ ...form, [e.currentTarget.name]: e.currentTarget.value });
    },
    [form],
  );

  return (
    <div className={b()}>
      <div className={b('header-info')}>
        <div className={b('header-info', 'block')}>
          <div className={b('header-info', 'block-title')}>{locale.newPassword}</div>
          <div className={b('header-info', 'input')}>
            <Input
              placeholder={locale.newPassword}
              onChange={changeValue}
              size="high"
              type="text"
              value={form.newPassword}
              name="newPassword"
            />
          </div>
        </div>
        <div className={b('header-info', 'block')}>
          <div className={b('header-info', 'block-title')}>{locale.repeatPassword}</div>
          <div className={b('header-info', 'input')}>
            <Input
              placeholder={locale.repeatPassword}
              onChange={changeValue}
              size="high"
              type="text"
              value={form.repeatPassword}
              name="repeatPassword"
            />
          </div>
        </div>
      </div>

      <div className={b('bottom')}>
        <div className={b('buttons')}>
          <Button callBack={closeModal} text={locale.cancel} size="high" color="modalWhite" />
          <Button type="submit" callBack={onSubmit} text={locale.save} size="high" color="modalBlue" />
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
