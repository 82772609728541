import { useSelector } from 'react-redux';
import { useMemo } from 'react';

export const agentTabs = [
  {
    name: 'All users',
    link: '/users/all',
  },
  {
    name: 'Agents',
    link: '/users/agents',
  },
  {
    name: 'Cashier - Agent',
    link: '/users/cashiers'
  },
  {
    name: 'Players',
    link: '/users/players',
  },
  {
    name: 'Disabled',
    link: '/users/disabled',
  },
];

const cashierTabs = [
  {
    name: 'All users',
    link: '/users/all',
  },
  {
    name: 'Players',
    link: '/users/players',
  },
  {
    name: 'Disabled',
    link: '/users/disabled',
  },
]

const useTabs = () => {
  const { role } = useSelector(state => state.auth);

  return useMemo(() => {
    return role === 6 ? agentTabs : cashierTabs;
  }, [role]);
};

export default useTabs;
