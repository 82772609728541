import changePasswordSVG from '../img/change-password.svg';
import disableSVG from '../img/disable.svg';
import editSVG from '../img/edit.svg';
import informationsSVG from '../img/informations.svg';
import lastMovementsSVG from '../img/last-movements.svg';
import permissionDetailsSVG from '../img/permission-details.svg';
import usernameSVG from '../img/username.svg';
import agentSVG from '../img/agent.svg';
import cashierSVG from '../img/cashier.svg';

import useAgentRoles from './useAgentRoles';
import useTabs from './useTabs';

const actionItems = {
  forEnabled: [
    {
      svg: changePasswordSVG,
      title: 'changePassword',
    },
    {
      svg: informationsSVG,
      title: 'lastMovements',
    },
    {
      svg: disableSVG,
      title: 'disable',
    },
    {
      svg: editSVG,
      title: 'edit',
    },
    {
      svg: informationsSVG,
      title: 'informations',
    },
    {
      svg: permissionDetailsSVG,
      title: 'permissionDetails',
    },
  ],
  forDisabled: [
    {
      svg: disableSVG,
      title: 'enable',
    },
  ],
};

const getRoleIcon = role => {
  const icons = {
    7: cashierSVG,
    6: agentSVG,
  };

  return icons[role] ?? usernameSVG;
};

const getActionItems = ({ role, isBanned }) => {
  if (isBanned) {
    return [
      {
        svg: disableSVG,
        title: 'enable',
      },
    ];
  }
  if (+role === 0) {
    return [
      {
        svg: changePasswordSVG,
        title: 'changePassword',
      },
      {
        svg: lastMovementsSVG,
        title: 'lastMovements',
      },
      {
        svg: disableSVG,
        title: 'disable',
      },
      {
        svg: informationsSVG,
        title: 'informations',
      },
      {
        svg: permissionDetailsSVG,
        title: 'permissionDetails',
      },
    ];
  }
  if (+role === 6 || +role === 7) {
    return [
      {
        svg: changePasswordSVG,
        title: 'changePassword',
      },
      {
        svg: lastMovementsSVG,
        title: 'lastMovements',
      },
      {
        svg: disableSVG,
        title: 'disable',
      },
      {
        svg: editSVG,
        title: 'edit',
      },
      {
        svg: informationsSVG,
        title: 'informations',
      },
      {
        svg: permissionDetailsSVG,
        title: 'permissionDetails',
      },
    ];
  }
};

export { useAgentRoles, useTabs, actionItems, getRoleIcon, getActionItems };
