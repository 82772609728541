import dayjs from 'dayjs';

export class FinanceApiConverter {
  convertFinanceHistory = data => {
    let arrTransfers = [];
    arrTransfers = data.transfers.map(el => ({
      id: el?.id,
      operation: el?.operation === 0 ? 'deposit' : 'withdrawal',
      // fromUserId: el?.from_user_id,
      // toUserId: el?.to_user_id,
      fromUser: el?.from_user,
      toUser: el?.to_user,
      currency: el?.currency,
      createdAt: el?.created_at,
      balanceBefore: el?.additional_data.balance_before,
      balanceAfter: el?.additional_data.balance_after,
    }));

    return {
      transfers: arrTransfers,
      details: {
        totalCount: data.details.total_count,
      },
    };
  };

  convertFlatPages = data => {
    return data.map(el => {
      return {
        priority: el.priority,
        createdAt: el.created_at,
        text: el.text,
        id: el.id,
        name: el.name,
        isActive: el.is_active,
        updatedAt: el.updated_at,
        idName: el.id_name,
        lang: el.lang,
      };
    });
  };
}
