import React, { useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import block from 'bem-cn';

import FilterBlock from './FilterBlock';
import Table from './Table/Table';

import './MyDebtAccount.scss';

const b = block('my-debt-account-desktop');

const MyDebtAccount = () => {
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(0);
  const financeHistory = useSelector(state => state.finance.financeHistory, shallowEqual);

  const onItemsOnPageChanged = newItemsOnPage => {
    setCount(newItemsOnPage);
    setPage(0);
  };

  return (
    <div className={b()}>
      <div className={b('filters')}>
        <FilterBlock count={count} currentPage={page} onPageClick={setPage} />
      </div>
      {/* {financeHistory.transfers.length !== 0 && ( */}
      <div className={b('table')}>
        <Table
          currentPage={page}
          onPageClick={setPage}
          onItemsOnPageChanged={onItemsOnPageChanged}
          thTdItems={financeHistory.transfers}
          totalCount={financeHistory.details.totalCount}
        />
      </div>
      {/* )} */}
    </div>
  );
};

export default MyDebtAccount;
